<template>
  <div class="login-flow-tpl" :class="{ compact }" :data-test-id="elementTest">
    <div class="inner-container" :class="{ 'u-p-less': compact }" :data-test-id="elementTest + '-container'">
      <div class="login-content" :data-test-id="elementTest + '-content'">
        <div class="login-content-wrapper" :data-test-id="elementTest + '-wrapper'">
          <div class="login-popup no-bg no-padding" :data-test-id="elementTest + '-popup'">
            <div class="login-title-wrapper" :data-test-id="elementTest + '-title-wrapper'">
              <button :data-test-id="elementTest + '-back-button'">
                <div
                  class="icon-arrow-left u-bg-steel-grey"
                  :data-test-id="elementTest + '-back-button-icon'"
                  @click="compact ? $emit('go-back') : $router.back()"
                />
              </button>
              <h1 :data-test-id="elementTest + '-title'">{{ $t('activateAccount.title') }}</h1>
            </div>
            <p class="login-subtitle" :data-test-id="elementTest + '-subtitle'">
              {{ $t('activateAccount.subtitle', emailOrPhone) }}
            </p>
            <form :data-test-id="elementTest + '-form'" @submit.prevent="activate">
              <InputField
                v-model="code"
                :input-id="'code'"
                input-type="text"
                :field-title="$t('fields.activationCode')"
                :placeholder-value="$t('fields.activationCode')"
                :element-test="elementTest + '-code'"
              >
                <template #icon>
                  <div class="icon-sms u-bg-steel-grey" :data-test-id="elementTest + '-code-icon'" />
                </template>
              </InputField>
              <div v-if="isLoading" class="loader" :data-test-id="elementTest + '-loader'" />
              <p v-if="error" class="error" :data-test-id="elementTest + '-error'">
                {{ error }}
              </p>
              <MainButton
                :label="forgotPassword ? $t('actions.resetPassword') : $t('actions.activateAccount')"
                :data-test-id="forgotPassword ? `${elementTest}-reset-password` : `${elementTest}-activate-account`"
                tag="button"
                class-variant="full-width"
                :disabled="code === null || code === ''"
              />
            </form>
            <MainButton
              tag="button"
              :label="$t('actions.resendCode', retryTime)"
              class-variant="full-width"
              :class="retryTime > 0 ? 'btn-light-grey-white' : 'u-bg-bg-grey u-c-dark-blue btn-hover-light-grey'"
              :disabled="retryTime > 0"
              :data-test-id="elementTest + '-resend-code'"
              @click="sendSMS"
            />
          </div>
        </div>
        <ImageLazy
          src="https://d3brsr9pdomwt0.cloudfront.net/illustrations/login-persons.webp"
          :alt="$t('brandName')"
          :title="$t('brandName')"
          :data-test-id="elementTest + '-illu'"
          class="login-main-illu"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import MainButton from '@/components/Buttons/MainButton/MainButton'
import InputField from '@/components/InputField/InputField'
import { useActivationStore } from '@/stores/activation'
import { useUserStore } from '@/stores/user'

const props = defineProps({
  compact: { type: Boolean, default: false },
  elementTest: { type: String, default: '' },
})
const emit = defineEmits(['go-back', 'confirm-activation'])
const { isLoading, request, error } = useApiRequest()

const code = ref(null)
const retryTime = ref(60)
const timer = ref(null)

const activationStore = useActivationStore()

const emailOrPhone = computed(() => {
  return activationStore.getEmailOrPhone
})

const forgotPassword = computed(() => {
  return activationStore.getForgotPassword
})

onMounted(() => {
  startRetryTimer()
})

onBeforeUnmount(() => {
  clearInterval(timer.value)
})

const startRetryTimer = () => {
  retryTime.value = 60
  timer.value = setInterval(() => {
    retryTime.value -= 1
    if (retryTime.value <= 0) {
      clearInterval(timer.value)
    }
  }, 1000)
}
const sendSMS = () => {
  request({
    req: useUserApi().forgotPassword(emailOrPhone.value),
  }).finally(() => {
    startRetryTimer()
  })
}
const activate = () => {
  const { $i18n } = useNuxtApp()
  const userStore = useUserStore()
  const { country } = useCurrentLocale()
  // Activate/reset password with code
  if (!isLoading.value && emailOrPhone.value && code.value) {
    isLoading.value = true
    error.value = null
    request({
      req: useUserApi().activate(emailOrPhone.value, code.value, forgotPassword.value),
    })
      .then(response => {
        if (response.user !== null && response.user !== undefined) {
          return userStore.login(response.token, response.user)
        }
        error.value = $i18n.te(`backendError.${response?.message}`)
          ? $i18n.t(`backendError.${response?.message}`, { phone: country?.phoneNumber })
          : $i18n.t('backendError.unknown', { phone: country?.phoneNumber })
        throw new Error(response?.message || 'unknown')
      })
      .then(() => {
        if (props.compact) {
          emit('confirm-activation', true)
        } else {
          const localePath = useLocalePath()
          const router = useRouter()
          router.push(localePath('login-set-password'))
        }
      })
      .catch(err => {
        error.value = $i18n.te(`backendError.${err?.message}`)
          ? $i18n.t(`backendError.${err?.message}`, { phone: country?.phoneNumber })
          : $i18n.t('backendError.unknown', { phone: country?.phoneNumber })
      })
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/partials/tpl/login-flow';
</style>
